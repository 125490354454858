.ZwembadOverzichtPage {
    .SimpleTitle-title {
        text-align: center;
    }
    .MediaTextGrey-content--title {
        max-width: 575px;
    }
    .MediaTextGrey-content--text {
        width: 85%;
        max-width: 495px;
    }

}

.TotalOffersNew {
    width: 100%;
    max-width: 1275px;
    margin: auto;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;

    @include mq($from: 640px) {
        grid-template-columns: 1fr 1fr;
        gap: 75px;
    }

    @include mq($from: 1024px) {
        gap: 125px;
    }


    &-tile {
        position: relative !important;
        transform: translate3d(0, 0, 0) !important;
        left: 0 !important;
        top: 0 !important;
        transition: box-shadow .3s ease;
        height: fit-content !important;

        &:hover {
            cursor: pointer;
            box-shadow: 0px 3px 36px #E2E2E2;

            .TotalOffersNew-tile--content {
                background-color: $white;
            }

            .TotalOffersNew-tile--content {
                svg {
                    opacity: 1;
                    -webkit-animation-name: arrowRightLeft;
                    -moz-animation-name: arrowRightLeft;
                    -o-animation-name: arrowRightLeft;
                    animation-name: arrowRightLeft;
                    animation-duration: 1.2s;
                    animation-iteration-count: infinite;
                }
            }
        }

        &--image {
            position: relative;
            width: 100%;
            height: 100%;
            // max-height: 353px;
            margin-bottom: 30px;

            // @include mq($until: 566px) {
            //     width: 100%;
            // }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                aspect-ratio: 4/3;
            }
        }

        &--content {
            padding-left: 30px;
            padding-bottom: 40px;
            transition: background-color .3s ease;

            @include mq($until: 768px) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        &--topTitle {
            font-size: 14px;
            margin-bottom: 0;
            font-weight: 500;
            font-family: $font-Gotham;
            color: $cc-green;
            text-transform: uppercase;
        }

        &--info {
            color: #808080;
            width: 100%;
            
            @include mq($from: 768px) {
                width: 85%; 
            }
        }

        &--title {
            font-size: 26px;
            margin-top: 10px;
            margin-bottom: 20px;
            font-weight: 300;
            font-family: $font-Gotham;
            color: $black;

            @include mq($until: 768px) {
                font-size: 20px;
            }

            svg {
                margin-left: 10px;
                opacity: 0;
                transition: opacity .3s ease;
            }
        }
    }
}


.TotalOffers {
    display: grid;
    grid-template-columns: 340px 468px 340px;
    grid-gap: 150px;
    justify-content: center;
    align-items: center;
    margin-top: 100px;    

    @include mq($from: 1151px, $until: 1450px) {
        grid-gap: 30px;
    }

    @include mq($from: 769px, $until: 1150px) {
        grid-template-columns: 340px 340px;
        grid-gap: 30px;
    }

    @include mq($from: 451px, $until: 768px) {
        grid-template-columns: 340px;
        margin-top: 50px;
        grid-gap: 30px;
    }

    @include mq($until: 450px) {
        grid-template-columns: 1fr;
        margin-top: 50px;
        grid-gap: 30px;
    }

    &-container {
        margin-top: 200px;
        margin-bottom: 200px;
        text-align: center;

        @include mq($until: 768px) {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        h1 {
            font-size: 45px;
            font-weight: 300;
            font-family: $font-Gotham;

            p {
                margin-bottom: 0;
            }

            @include mq($until: 768px) {
                font-size: 35px;
            }
        }
    }

    &-tile {
        width: 100%;
        transition: box-shadow .3s ease;

        &:nth-child(2), &:nth-child(5) {
            .TotalOffers-tile--image {
                height: 597px;

                @include mq($until: 1150px) {
                    height: 340px;
                    width: 100%;
                }
            }
        }

        &:hover {
            cursor: pointer;
            box-shadow: 0px 3px 36px #E2E2E2;

            .TotalOffers-tile--content {
                background-color: $white;
            }

            .TotalOffers-tile--button {
                svg {
                    display: block;
                    -webkit-animation-name: arrowRightLeft;
                    -moz-animation-name: arrowRightLeft;
                    -o-animation-name: arrowRightLeft;
                    animation-name: arrowRightLeft;
                    animation-duration: 1.2s;
                    animation-iteration-count: infinite;
                }
            }
        }

        &--image {
            width: 100%;
            height: 340px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--content {
            padding: 40px 20px;
            text-align: center;
        }

        &--title {
            font-size: 26px;
            font-weight: 300;
            font-family: $font-Gotham;
            margin-bottom: 25px;
        }

        &--button {
            font-weight: 500;
            font-size: 16px;
            font-family: $font-Gotham;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                display: none;
                margin-left: 10px;
                transition: display .3s ease;
            }
        }
    }

    &.amount-1 {
        grid-template-columns: 468px;

        .TotalOffers-tile {
            &:nth-child(2) {
                .TotalOffers-tile--image {
                    height: 597px;

                    @include mq($until: 1150px) {
                        height: 340px;
                        width: 100%;
                    }
                }
            }
        }
    }

    &.amount-2, &.amount-4 {
        grid-template-columns: repeat(2, 400px);

        .TotalOffers-tile {
            &:nth-child(2) {
                .TotalOffers-tile--image {
                    height: 340px;

                    @include mq($until: 1150px) {
                        height: 340px;
                        width: 100%;
                    }
                }
            }
        }

        @include mq($until: 960px) {
            grid-template-columns: 340px;
        }
    }   

    &.amount-5 {
        grid-template-columns: repeat(3, 400px);

        .TotalOffers-tile {
            &:nth-child(2), &:nth-child(5) {
                .TotalOffers-tile--image {
                    height: 340px;

                    @include mq($until: 1150px) {
                        height: 340px;
                        width: 100%;
                    }
                }
            }
        }

        @include mq($until: 1350px) {
            grid-template-columns: repeat(2, 400px);            
        }

        @include mq($until: 900px) {
            grid-template-columns: 340px;
        }
    }
}