.BlogBlock {
    max-width: 1100px;
    margin: 200px auto;

    @include mq($from: 961px, $until: 1150px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include mq($until: 960px) {
        margin: 50px auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    &-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;

        @include mq($until: 960px) {
            flex-direction: column;
        }

        &--cta {
            display: flex;
            align-items: flex-end;
        }
    }

    &-subtitle {
        color: $cc-green;
        font-size: 14px;
        font-weight: 500;
        font-family: $font-Gotham;
        margin-bottom: 30px;
        text-transform: uppercase;
        letter-spacing: 1px;

        @include mq($until: 600px) {
            margin-bottom: 20px;
        }
    }

    &-title {
        font-size: 45px;
        font-family: $font-Gotham;
        font-weight: 200;
        margin-bottom: 0;

        p {
            margin-bottom: 0;
        }


        @include mq($from: 601px, $until: 960px) {
            margin-bottom: 30px;
        }

        @include mq($until: 600px) {
            font-size: 35px;
            margin-bottom: 30px;
        }
    }

    &-tiles {
        a {
            &:first-child {

                .Blog-tile {
                    margin-bottom: 60px;
                }
            }
        }
    }
}