/* Navigation */
.Navigation {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
        flex-direction: row-reverse;
    }

    &-container {
        display: flex;
        align-items: flex-start;
        width: 100%;

        @include mq($from: 601px, $until: 1050px, $media-type: $mq-type, $mq_debug: null) {
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 100px;
            // margin-top: 10px;
        } 

        @include mq($until: 600px, $media-type: $mq-type, $mq_debug: null) {
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 50px;
            // margin-top: 10px;
        } 
        
    }

    &-button {
        display: none;
    }

    #menu-button {
        position: relative;
        z-index: 5;
        cursor: pointer;
        user-select: none;
        height: 20px;
        margin-top: 5px;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;

        @include mq($from: 1051px, $media-type: $mq-type, $mq_debug: null) {
            display: none !important;
        } 

        span {
            display: block;
            position: relative;
            height: 1px;
            width: 30px;
            border-radius: 2px;
            opacity: 1;
            background-color: $black;

            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            -o-transition: .3s ease-in-out;
            transition: .3s ease-in-out;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 7px;
            }

            &:nth-child(3) {
                top: 14px;
            }
        }
    }


    &-button:checked ~ #menu-button {
        
        span {
            background-color: $white;
            
            &:nth-child(1) {
                top: 11px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                top: 9px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }
        }
    }

    &-button:checked ~ .Navigation-body {
        transform: translateX(0);
        transition: all .3s ease-in-out;
        z-index: -1;
    }

    &-button:checked ~ .Logo {
        svg {
            path {
                transition: .5s fill ease;
                fill: $white;
            }
        } 
    }

    &-body {
        visibility: visible;
        z-index: 2;
        display: flex;
        height: 100%;
        align-items: center;
        margin-top: 50px;

        @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
            position: fixed;
            top: 0;
            right: 0;
            min-height: 100vh;
            height: -webkit-fill-available;
            width: 100vw;
            background-color: $black;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            transform: translateX(100vw);
            transition: all .3s ease-in-out;
            padding-top: 100px;
            padding-bottom: 50px;
            flex-direction: column;
            margin-top: 0;
        } 
    }

    &-top {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &-socials {
        display: flex;
        align-items: flex-start;
        margin-left: 30px;
        margin-top: -3px;

        @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
            width: 25%;
            margin: 20px auto 20px;
            justify-content: space-between;
        } 

        
        .Socials-icon {
            margin-right: 15px;

            svg {
                height: 12px;
                width: auto;

                @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
                    height: 20px;
                } 

                path {
                    transition: .3s ease fill;

                    @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
                        fill: $white;
                    } 
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                svg {
                    path {
                        fill: $black;

                        @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
                            fill: $cc-green;
                        } 
                    }
                }
            }
        }
    }
}

/* Types */
@import '6-components/navigation/main';
@import '6-components/navigation/lang';
@import '6-components/navigation/footer';
