.Contact {
    display: grid;
    grid-template-columns: 320px 480px;
    grid-gap: 160px;
    justify-content: center;
    margin-bottom: 150px;

    @include mq($from: 769px, $until: 1024px) {
        grid-template-columns: 768px;
        grid-gap: 50px;
        justify-content: center;
    }

    @include mq($until: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 50px;
        padding: 0 20px;
        margin-bottom: 50px;
    }

    &-content {

        &--info {
            color: $cc-grey;
            font-size: 16px;
            font-weight: 400;
            font-family: $font-Gotham;
            margin-bottom: 50px;
        }

        &--title {
            color: $black;
            font-size: 14px;
            font-family: $font-Gotham;
            font-weight: 500;
            margin-bottom: 24px !important;
        }

        &--adres {
            color: $cc-grey;
            font-size: 16px;
            font-weight: 400;
            font-family: $font-Gotham;
            margin-bottom: 40px;

            p {
                margin-bottom: 0;
            }
        }

        &--data {
            display: flex;
            flex-direction: column;
            
            a {
                color: $cc-grey;
                font-size: 16px;
                font-weight: 400;
                font-family: $font-Gotham;

                &:hover {
                    color: $black;
                }
            }
        }
    }

    &-form {
        .Form {

            &-label {
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                color: $black;
                letter-spacing: 1px;
                margin-bottom: 0;
                position: relative;

                &.required {
                    &:after {
                        content: '*';
                        position: absolute;
                        right: -10px;
                        top: -5px;
                        color: #e62a2a;
                        z-index: 1;
                    }
                }
            }

            &-controls {
                margin-bottom: u(30);
                width: 100%;

                &:nth-child(2) {
                    margin-left: 20px;
                }

                &.houseNumber, &.postal {
                    width: 40%;
                }
            }

            .ff-form-errors, .ff-errors {
                color: $red;
            }

            .Subscribe-grid {
                display: flex;
                width: 100%;
                justify-content: center;
            }

            @include mq($until: 500px) {
                &-controls {
                    &:nth-child(2) {
                        margin-left: 0;
                    }

                    &.houseNumber, &.postal {
                        width: 100%;
                    }
                }
                
                .Subscribe-grid {
                    flex-direction: column;
                    align-items: center;
                }
            }
            
            &-field, &--input {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 100%;
                padding: 15px 0;
                border-radius: 0;
                border-top: hidden;
                border-left: hidden;
                border-right: hidden;
                border-bottom: 2px solid #B4B4B4;

                &:active, &:focus {
                    border-bottom: 2px solid $black;
                }

                &::placeholder {
                    color: #B4B4B4;
                    font-size: 16px;
                    font-family: $font-Gotham;
                    font-weight: 400;
                }
            }

            .Checkbox-container {
                label {
                    font-size: 12px;
                    font-weight: 400;
                    font-family: $font-Gotham;
                    color: #000000;
                    text-transform: none;
                    display: flex;
                }

                input[type="checkbox"]:checked {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none; /* remove arrow */
                    background-color: $cc-green !important;
                    box-shadow: inset 0px 0px 0px 3px #ffffff;
                }
            }

            &-checkbox {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 25px;
                height: 22px;
                border: 1px solid #B4B4B4;
                margin-right: 10px;
            }

            p {
                font-size: 12px;
                font-weight: 400;
                font-family: $font-Gotham;
                color: #B4B4B4;
                line-height: 22px;

                a {
                    color: #B4B4B4;
                    text-decoration-color: #B4B4B4;

                    &:hover {
                        color: $black;
                        text-decoration-color: $black;
                    }
                }
            }
        }
    }
}

.GoogleMaps {
    max-width: 1280px;
    margin: 0 auto 200px;

    @include mq($until: 1024px) {
        margin: 0px auto 50px;
    }

    .Maps {
        height: 555px;
    }
}