/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-Gotham:                           "Gotham", sans-serif !default; //100, 200, 300, 400, 500, 700, 900

//GOTHAM

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Thin.woff2') format('woff2'),
        url('../fonts/Gilroy-Thin.woff') format('woff');
    font-style: normal;
    font-weight: 100;
}
@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-ThinIta.woff2') format('woff2'),
        url('../fonts/Gilroy-ThinIta.woff') format('woff');
    font-style: italic;
    font-weight: 100;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-XLight.woff2') format('woff2'),
        url('../fonts/Gilroy-XLight.woff') format('woff');
    font-style: normal;
    font-weight: 200;
}
@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-XLightIta.woff2') format('woff2'),
        url('../fonts/Gilroy-XLightIta.woff') format('woff');
    font-style: italic;
    font-weight: 200;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gilroy-Light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-LightIta.woff2') format('woff2'),
        url('../fonts/Gilroy-LightIta.woff') format('woff');
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gilroy-Book.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-BookIta.woff2') format('woff2'),
        url('../fonts/Gilroy-BookIta.woff') format('woff');
    font-style: italic;
    font-weight: 400;
}

@font-face {
  font-family: "Gotham";
  src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
      url('../fonts/Gilroy-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
}
@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-MediumIta.woff2') format('woff2'),
        url('../fonts/Gilroy-MediumIta.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gilroy-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-BoldIta.woff2') format('woff2'),
        url('../fonts/Gilroy-BoldIta.woff') format('woff');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-Black.woff2') format('woff2'),
        url('../fonts/Gilroy-Black.woff') format('woff');
    font-style: normal;
    font-weight: 900;
}
@font-face {
    font-family: "Gotham";
    src: url('../fonts/Gotham-BlackIta.woff2') format('woff2'),
        url('../fonts/Gilroy-BlackIta.woff') format('woff');
    font-style: italic;
    font-weight: 900;
}