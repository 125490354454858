.MediaTextGrey {
    background-color: $cc-lightGrey;
    margin: 200px 0 200px 320px;
    padding: 150px 0;
    display: grid;
    grid-template-columns: 800px 550px;
    align-items: center;
    max-width: 1920px;

    @include mq($from: 1920px) {
        justify-content: center;
        margin-left: auto;
    }

    @include mq($from: 1100px, $until: 1600px) {
        grid-template-columns: 1fr 550px;
        margin: 200px 0 200px 0;
        padding: 50px 20px;
    }

    @include mq($from: 769px, $until: 1100px) {
        grid-template-columns: 800px;
        justify-content: center;
        margin: 100px 0 100px 0;
        padding: 50px 20px;
    }

    @include mq($until: 768px) {
       grid-template-columns: 1fr;
       margin: 50px 0 50px 0;
       padding: 50px 20px;
    }
    
    &-image {
        margin-left: -320px;
        width: 800px;
        height: 600px;

        @include mq($from: 1100px, $until: 1250px) {
            width: 100%;
        }

        @include mq($from: 769px, $until: 1100px) {
            width: 100%;
            height: 650px;
            margin-left: 0;
            margin-bottom: 50px;
        }

        @include mq($until: 768px) {
            width: 100%;
            height: 320px;
            margin-left: 0;
            margin-bottom: 30px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-content {
        position: relative;
        margin-left: -160px;

        @include mq($until: 1100px) {
            margin-left: 0;
            // padding: 0 20px;
         }

        &--subtitle {
            color: $cc-green;
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: 500;
            text-transform: uppercase;
            font-family: $font-Gotham;
            margin-bottom: 30px;

            @include mq($until: 768px) {
                margin-bottom: 20px;
            }
        }

        &--title {
            max-width: 420px;
            font-size: 45px;
            font-family: $font-Gotham;
            font-weight: 200;
            color: $black;
            margin-bottom: 60px;

            @include mq($until: 768px) {
                margin-bottom: 30px;
                font-size: 35px;
            }
        }

        &--text {
            margin-bottom: 50px;

            @include mq($until: 768px) {
                margin-bottom: 30px;
             }

            p {
                color: $cc-grey;
            }

            ul {
                li {
                    color: $cc-grey;
                }
            }
        }

        &--step {
            display: grid;
            grid-template-columns: 100px 1fr;
            align-items: center;
            margin-bottom: 30px;

            @include mq($until: 430px) {
                grid-template-columns: 1fr;
            }

            &:last-child {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;

                &:first-child {
                    color: $cc-green;
                    font-weight: 500;
                    font-style: normal;
                    margin-right: 20px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 14px;
                    font-family: $font-Gotham;
                }
            }
        }

        &--image {
            position: absolute;
            bottom: -380px;
            left: 0;
            width: 480px;
            height: 300px;

            @include mq($from: 769px, $until: 1100px) {
                position: relative;
                bottom: unset;
                left: unset;
                margin-top: 50px;
            }

            @include mq($until: 768px) {
                width: 100%;
                position: relative;
                bottom: unset;
                left: unset;
                margin-top: 50px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.TextMediaGrey {
    background-color: $cc-lightGrey;
    margin: 200px 320px 200px 0;
    padding: 150px 0 150px 320px;
    display: grid;
    grid-template-columns: 550px 800px;
    align-items: center;
    max-width: 1920px;

    @include mq($from: 1920px) {
        justify-content: center;
        margin-left: 0;
    }
    
    @include mq($from: 1281px, $until: 1600px) {
        grid-template-columns: 550px 1fr ;
        margin: 200px 0 200px 0;
        padding: 50px 20px;
    }

    @include mq($from: 769px, $until: 1100px) {
        grid-template-columns: 800px;
        justify-content: center;
        margin: 100px 0 100px 0;
        padding: 50px 20px;
    }

    @include mq($until: 768px) {
       grid-template-columns: 1fr;
       margin: 50px 0 50px 0;
       padding: 50px 20px;
    }

    &-image {
        margin-left: 250px;
        width: 800px;
        height: 600px;

        @include mq($from: 769px, $until: 1110px) {
            width: 100%;
            height: 650px;
            margin-left: 0;
            margin-top: 50px;
        }

        @include mq($until: 768px) {
            width: 100%;
            height: 320px;
            margin-left: 0;
            margin-top: 30px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-content {
        position: relative;
    }
}