.SimpleTitle {
    max-width: 960px;
    margin: 120px auto 100px;

    @include mq($until: 1024px) {
        margin: 50px auto 50px;
        padding-left: 20px;
        padding-right: 20px;
    }

    &-title {
        font-size: 45px;
        font-weight: 200;
        margin-bottom: 0;

        p {
            margin-bottom: 0;
        }

        @include mq($until: 768px) {
            font-size: 35px;
        }
    }
}

.BlogPage {
    .SimpleTitle {
        max-width: 1100px;
        margin: 120px auto 100px;

        @include mq($until: 768px) {
            margin: 50px auto 50px;
        }
    }

    .BlogDetailPage {
        .SimpleTitle {
            max-width: 960px;
            margin: 0 auto 0;
            padding: 120px 0 50px;

            @include mq($until: 768px) {
                padding: 50px 20px 50px;
            }
        }
    }
}

.RealisationsPage, .TotalOfferPage {
    .SimpleTitle {
        text-align: center;
        margin: 120px auto 50px;

        @include mq($until: 768px) {
            margin: 50px auto 50px;
        }
    }
}