/**
 * Size: Large
 */

 @include mq($from: $viewport--xxl) {

  .u-xxl-size1of12 {
      flex-basis: auto !important;
      width: calc(100% * 1 / 12) !important;
  }

  .u-xxl-size1of10 {
      flex-basis: auto !important;
      width: 10% !important;
  }

  .u-xxl-size1of8 {
      flex-basis: auto !important;
      width: 12.5% !important;
  }

  .u-xxl-size1of6,
  .u-xxl-size2of12 {
      flex-basis: auto !important;
      width: calc(100% * 1 / 6) !important;
  }

  .u-xxl-size1of5,
  .u-xxl-size2of10 {
      flex-basis: auto !important;
      width: 20% !important;
  }

  .u-xxl-size1of4,
  .u-xxl-size2of8,
  .u-xxl-size3of12 {
      flex-basis: auto !important;
      width: 25% !important;
  }

  .u-xxl-size3of10 {
      flex-basis: auto !important;
      width: 30% !important;
  }

  .u-xxl-size1of3,
  .u-xxl-size2of6,
  .u-xxl-size4of12 {
      flex-basis: auto !important;
      width: calc(100% * 1 / 3) !important;
  }

  .u-xxl-size3of8 {
      flex-basis: auto !important;
      width: 37.5% !important;
  }

  .u-xxl-size2of5,
  .u-xxl-size4of10 {
      flex-basis: auto !important;
      width: 40% !important;
  }

  .u-xxl-size5of12 {
      flex-basis: auto !important;
      width: calc(100% * 5 / 12) !important;
  }

  .u-xxl-size1of2,
  .u-xxl-size2of4,
  .u-xxl-size3of6,
  .u-xxl-size4of8,
  .u-xxl-size5of10,
  .u-xxl-size6of12 {
      flex-basis: auto !important;
      width: 50% !important;
  }

  .u-xxl-size7of12 {
      flex-basis: auto !important;
      width: calc(100% * 7 / 12) !important;
  }

  .u-xxl-size3of5,
  .u-xxl-size6of10 {
      flex-basis: auto !important;
      width: 60% !important;
  }

  .u-xxl-size5of8 {
      flex-basis: auto !important;
      width: 62.5% !important;
  }

  .u-xxl-size2of3,
  .u-xxl-size4of6,
  .u-xxl-size8of12 {
      flex-basis: auto !important;
      width: calc(100% * 2 / 3) !important;
  }

  .u-xxl-size7of10 {
      flex-basis: auto !important;
      width: 70% !important;
  }

  .u-xxl-size3of4,
  .u-xxl-size6of8,
  .u-lg-size9of12 {
      flex-basis: auto !important;
      width: 75% !important;
  }

  .u-xxl-size4of5,
  .u-xxl-size8of10 {
      flex-basis: auto !important;
      width: 80% !important;
  }

  .u-xxl-size5of6,
  .u-xxl-size10of12 {
      flex-basis: auto !important;
      width: calc(100% * 5 / 6) !important;
  }

  .u-xxl-size7of8 {
      flex-basis: auto !important;
      width: 87.5% !important;
  }

  .u-xxl-size9of10 {
      flex-basis: auto !important;
      width: 90% !important;
  }

  .u-xxl-size11of12 {
      flex-basis: auto !important;
      width: calc(100% * 11 / 12) !important;
  }


  // Properties

  .u-xxl-sizeFit {
      flex-basis: auto !important;
  }

  .u-xxl-sizeFill {
      flex: 1 1 0% !important;
      flex-basis: 0% !important;
  }

  .u-xxl-sizeFillAlt {
      flex: 1 1 auto !important;
      flex-basis: auto !important;
  }

  .u-xxl-sizeFull {
      width: 100% !important;
  }

}