.CallToAction {
    position: relative;
    overflow: hidden;
    background-color: $cc-lightGrey;
    margin-top: 200px;
    padding: 150px 0;

    @include mq($until: 600px) {
        padding: 50px 0;
        margin-top: 50px;
    }

    &-container {
        max-width: 1140px;
        margin: 0 auto;
    }

    &-image {
        position: absolute;
        top: -280px;
        right: 45%;
        z-index: 1;

        @include mq($until: 600px) {
            top: -400px;
            right: 25%;

            svg {
                width: 350px;
            }
        }
    }

    &-content {
        text-align: center;
        z-index: 10;
        position: relative;

        &--title {
            font-size: 45px;
            font-weight: 300;
            font-family: $font-Gotham;
            color: $cc-black;
            line-height: 35px;
            text-align: center;
            margin-bottom: 50px;

            p {
                // margin-bottom: 0;

                @include mq($until: 600px) {
                    margin-bottom: 0;
                }
            }

            strong {
                font-weight: 500;
            }

            @include mq($until: 600px) {
                font-size: 25px;
                margin-bottom: 30px;
            }
        }
    }
}