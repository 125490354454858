.Jobs {
    &-detail {
        .image {
            height: 600px;
            overflow: hidden;

            img {
                width: 100%;
                height: inherit;
                object-fit: cover;
            }

            @media only screen and (max-width: 1000px) {
                height: 400px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 120px;
            max-width: 1080px;
            margin-left: auto;
            margin-right: auto;

            .info {                
                h2 {
                    font-weight: 300;
                    margin-bottom: 40px;
                }
            }

            @media only screen and (max-width: 1000px) {
                margin-top: 80px;
                padding: 0 40px;
            }
        }
        
        &--info {
            margin-top: 80px;

            @include mq($until: $viewport--sm) {
                margin-top: 40px;
            }
        }

        &--content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 80px;
            margin-bottom: 60px;

            @include mq($until: $viewport--sm) {
                flex-direction: column-reverse;
            }
        }

        &--back {
            a {
                font-weight: 500;
            }
        }

        &--text {
            color: #808080;
        }

        &--share {
            display: flex;
            align-items: center;

            > span {
                color: #808080;
                font-weight: 500;
                text-transform: uppercase;     
                padding-right: 5px;          
            }

            > a {
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #808080;
                border-radius: 100%;
                margin-left: 10px;

                svg {
                    path {
                        fill: #808080;
                    }
                }
            }

            @include mq($until: $viewport--sm) {
                margin-top: 0;
                margin-bottom: 40px;
            }
        }
    }

    &.overview-intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 120px;
        padding-left: 40px;
        padding-right: 40px;

        .title, .text {
            text-align: center;
        }

        .text {
            margin-top: 50px;
        }

        @include mq($until: 1050px) {
            padding-top: 20px;
        }
    }

    &-overview {
        display: grid;
        grid-template-columns: repeat(2, 600px);
        grid-gap: 120px 80px;
        justify-content: center;
        align-items: flex-start;
        margin-top: 240px;
        
        @include mq($until: 1440px) {
            grid-template-columns: repeat(2, 500px);
            grid-gap: 80px 50px;
        }
       
        @include mq($until: 1140px) {
            grid-template-columns: repeat(2, 450px);
        }
        
        @include mq($until: 1050px) {
            grid-template-columns: 500px;
            margin-top: 40px;
        }
        
        @include mq($until: 600px) {
            grid-template-columns: 100%;
            grid-gap: 40px 0;
        }
    }

    &-tile {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 3px 36px rgba(226,226,226,0);
        transition: box-shadow .4s ease;

        > figure {
            height: 350px;
            overflow: hidden;

            img {
                width: 100%;
                height: inherit;
                object-fit: cover;
                transform: scale(1.01);
                transition: transform .4s ease;
            }

            @include mq($until: 1140px) {
                height: 300px;
            }
        }

        &--info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 30px;
            background-color: white;

            &_left {
                padding-right: 20px;

                h3 {
                    color: $cc-green;
                    text-transform: uppercase;
                    font-size: 13px;
                    margin-bottom: 10px;
                }

                h2 {
                    font-weight: 400;
                    margin-bottom: 0;
                    font-weight: 300;

                    @include mq($until: $viewport--sm) {
                        font-size: 20px;
                    }
                }
            }

            @include mq($until: $viewport--sm) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .Button {
            pointer-events: none;
            white-space: nowrap;

            @include mq($until: $viewport--sm) {
                margin-top: 10px;
                margin-left: auto;
            }
        }                


        &:hover {
            box-shadow: 0px 3px 30px rgba(226,226,226,1);

            > figure {
                img {
                    transform: scale(1.05);
                }
            }

            .Button {
                background-color: $cc-green;
                color: white;
            }
        }

        &:nth-of-type(even) {
            margin-top: 120px;
        }
        
        &:nth-of-type(odd) {
            margin-top: -120px;
        }

        @include mq($until: 1050px) {
            &:nth-of-type(even), &:nth-of-type(odd) {
                margin-top: 0;
            }            
        }
    }

    &.spontaan {
        @media only screen and (min-width: 768px) {
            padding-top: 160px;
        }
    }
}