.Jobs {
    &-form {
        margin-top: 60px;

        .Form {
            .freeform-form-has-errors {
                color: red;
                margin-bottom: 40px;
            }

            &-errors {
                display: none;
            }

            &-label {
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                color: $black;
                letter-spacing: 1px;
                margin-bottom: 0;
                position: relative;

                &.required {
                    &:after {
                        content: '*';
                        position: absolute;
                        right: -10px;
                        top: -5px;
                        color: #e62a2a;
                        z-index: 1;
                    }
                }
            }

            &-controls {
                margin-bottom: u(30);
                width: 100%;

                &:nth-child(2) {
                    margin-left: 20px;
                }

                &.houseNumber, &.postal {
                    width: 40%;
                }
            }

            .ff-form-errors, .ff-errors {
                color: $red;
            }

            .row {
                display: flex;
                width: 100%;
                justify-content: center;

                &:last-of-type {
                    justify-content: flex-start;
                }

                .cv, .motivation {
                    input {
                        position: absolute;
                        opacity: 0;
                    }

                    .files-container {
                        width: 100%;
                        height: 50px;
                        border: 1px dashed #808080;
                        margin-top: 10px;

                        label {
                            height: 100%;
                            display: flex;
                            width: 100%;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            p {
                                margin-bottom: 0;
                                color: #808080;
                                font-weight: 400px;

                                &.filename {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding: 0 20px;

                                    button {
                                        cursor: pointer;
                                    }
                                }
                            }
                            
                            .hidden {
                                display:none !important;
                            }
                        }
                    }
                }

                textarea {
                    resize: none;
                }
            }

            @include mq($until: 500px) {
                &-controls {
                    &:nth-child(2) {
                        margin-left: 0;
                    }

                    &.houseNumber, &.postal {
                        width: 100%;
                    }
                }
                
                .row {
                    flex-direction: column;
                    align-items: center;
                }
            }
            
            &-field, &--input {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 100%;
                padding: 15px 0;
                border-radius: 0;
                border-top: hidden;
                border-left: hidden;
                border-right: hidden;
                border-bottom: 2px solid #B4B4B4;

                &:active, &:focus {
                    border-bottom: 2px solid $black;
                }

                &::placeholder {
                    color: #B4B4B4;
                    font-size: 16px;
                    font-family: $font-Gotham;
                    font-weight: 400;
                }
            }

            .Checkbox-container {
                label {
                    font-size: 12px;
                    font-weight: 400;
                    font-family: $font-Gotham;
                    color: #000000;
                    text-transform: none;
                    display: flex;
                }

                input[type="checkbox"]:checked {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none; /* remove arrow */
                    background-color: $cc-green !important;
                    box-shadow: inset 0px 0px 0px 3px #ffffff;
                }
            }

            &-checkbox {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 25px;
                height: 22px;
                border: 1px solid #B4B4B4;
                margin-right: 10px;
            }

            p {
                font-size: 12px;
                font-weight: 400;
                font-family: $font-Gotham;
                color: #B4B4B4;
                line-height: 22px;

                a {
                    color: #B4B4B4;
                    text-decoration-color: #B4B4B4;

                    &:hover {
                        color: $black;
                        text-decoration-color: $black;
                    }
                }
            }
        }
    }
}