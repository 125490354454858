.Video {
    max-width: 960px;
    margin: 350px auto 200px;

    @include mq($until: 768px) {
        margin: 50px auto 50px;
    }

    &-title {
        font-size: 45px;
        font-family: $font-Gotham;
        font-weight: 200;
        margin-bottom: 85px;

        p {
            margin-bottom: 0;
        }

        @include mq($from: 769px, $until: 960px) {
            margin-left: 20px;
            margin-right: 20px;
        }

        @include mq($until: 768px) {
            font-size: 35px;
            margin-bottom: 50px;
            margin-left: 20px;
            margin-right: 20px;
        }

    }

    &-container {
        position: relative;
        width: 100%;
        height: 505px;
        overflow: hidden;

        @include mq($until: 768px) {
            height: auto;
        }
        
    }
    
    &-item {
        width: 100%;
        height: 100%;
        transform: scale(1.1);
    }
    
    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($color: #000000, $alpha: 0.55);
        width: 100%;
        height: 100%;
        transition: opacity .3s ease;

        &:hover {
            cursor: pointer;
        }
    }
}