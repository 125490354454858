.Realisations {


    &-filter {
        display: flex;
        justify-content: center;
        margin-bottom: 100px;

        @include mq($until: 768px) {
            margin-bottom: 0px;
        }

        &--container {
            
            @include mq($until: 750px) {
                display: flex;
                flex-direction: column;
                text-align: center;
            }
        }

        &--button {
            margin-left: 25px;
            margin-right: 25px;
            color: $cc-grey;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            font-family: $font-Gotham;

            @include mq($until: 800px) {
                margin: 10px 15px;
            }

            &:hover {
                cursor: pointer;
            }

            &.is-active {
                color: $cc-green;
            }
        }
    }

    &-tiles {
        display: grid;
        grid-template-columns: repeat(2, 566px);
        grid-gap: 0 140px;
        justify-content: center;
        margin-bottom: 200px;
        height: fit-content !important;

        @include mq($from: 1151px, $until: 1280px) {
            justify-content: center;
            grid-gap: 0 50px;
        }

        @include mq($from: 566px, $until: 1150px) {
            grid-template-columns: 566px;
            justify-content: center;
            grid-gap: 0 50px;
            margin-bottom: 50px;
        }

        @include mq($until: 565px) {
            grid-template-columns: 1fr;
            grid-gap: 0 50px;
            margin-bottom: 50px;
        }
    }

    &-tile {
        position: relative !important;
        transform: translate3d(0, 0, 0) !important;
        left: 0 !important;
        top: 0 !important;
        transition: box-shadow .3s ease;
        height: fit-content !important;

        @include mq($until: 768px) {
            margin-top: 50px;
        }

        &:nth-child(even) {
            margin-top: 150px;

            @include mq($until: 1024px) {
                margin-top: 50px;
            }
        }

        &:hover {
            cursor: pointer;
            box-shadow: 0px 3px 36px #E2E2E2;

            .Realisations-tile--content {
                background-color: $white;
            }

            .Realisations-tile--content {
                svg {
                    opacity: 1;
                    -webkit-animation-name: arrowRightLeft;
                    -moz-animation-name: arrowRightLeft;
                    -o-animation-name: arrowRightLeft;
                    animation-name: arrowRightLeft;
                    animation-duration: 1.2s;
                    animation-iteration-count: infinite;
                }
            }
        }

        &--image {
            position: relative;
            width: 100%px;
            height: 353px;
            margin-bottom: 30px;

            // @include mq($until: 566px) {
            //     width: 100%;
            // }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--award {
            position: absolute;
            top: 20px;
            right: 20px;
            background-color: #141414;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 73px;
            height: 73px;
            border-radius: 50%;

            p {
                margin-bottom: 0;
                color: $white;
                text-transform: uppercase;
                font-size: 10px;
                font-family: $font-Gotham;
                font-weight: 500;
            }
        }

        &--content {
            padding-left: 30px;
            padding-bottom: 40px;
            transition: background-color .3s ease;

            @include mq($until: 768px) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        &--type {
            font-size: 14px;
            margin-bottom: 0;
            font-weight: 500;
            font-family: $font-Gotham;
            color: $cc-green;
        }

        &--title {
            font-size: 26px;
            margin-top: 20px;
            margin-bottom: 0;
            font-weight: 300;
            font-family: $font-Gotham;
            color: $black;

            @include mq($until: 768px) {
                font-size: 20px;
            }

            svg {
                margin-left: 10px;
                opacity: 0;
                transition: opacity .3s ease;
            }
        }
    }
}

.RealisationsDetail {
    max-width: 960px;
    margin: 120px auto 200px;

    @include mq($from: 769px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
        margin: 100px auto 100px;
    }

    @include mq($until: 768px) {
        margin: 50px auto 50px;
    }

    &-banner {
        width: 100vw;
        height: 1010px;

        @include mq($from: 769px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            height: 505px;
        }

        @include mq($until: 768px) {
            height: 320px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-content {

        @include mq($until: 960px) {
            padding: 0 20px;
        }

        h1 {
            font-size: 45px;
            margin-top: 20px;
            margin-bottom: 50px;
            font-weight: 300;
            font-family: $font-Gotham;
            color: $black;

            @include mq($until: 768px) {
                font-size: 35px;
                margin-bottom: 30px;
            }
        }

        &--text {
            color: $cc-grey;
            margin-bottom: 50px;

            @include mq($until: 768px) {
                margin-bottom: 30px;
            }
        }

        &--info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 80px;

            @include mq($until: 768px) {
                flex-direction: column;
                justify-content: flex-start;
                margin-bottom: 50px;
            }

            .Jobs-detail--share {
                display: flex;
                align-items: flex-end;
            }
        }

        &--container {
            display: grid;
            grid-template-columns: 100px 1fr;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            > div {
                color: $cc-grey;
            }

            p {
                margin-bottom: 0;
                font-weight: 500;
                font-size: 16px;
                font-family: $font-Gotham;
                text-transform: uppercase;
                color: $cc-grey;

            }
        }
    }

    &-images {
        margin-top: 100px;
        margin-bottom: 100px;

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    &-image {

        &--one {
            width: 100%;
            height: 598px;
            margin-bottom: 50px;

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                height: 320px;
                margin-top: 50px;
                margin-bottom: 50px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--two {
            display: grid;
            grid-template-columns: repeat(2, 455px);
            grid-template-rows: 598px;
            overflow: hidden;
            grid-gap: 50px;
            margin-top: 50px;
            margin-bottom: 50px;

            @include mq($from: 769px, $until: 1200px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 50px;
            }

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                margin-top: 50px;
                margin-bottom: 50px;
                grid-template-rows: 320px 320px;
                grid-template-columns: 1fr;
                grid-gap: 50px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &-back {
        display: flex;
        justify-content: space-between;

        @include mq($from: 601px, $until: 768px, $media-type: $mq-type, $mq_debug: null) {
            padding: 0 20px;
        }

        @include mq($until: 600px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
            justify-content: flex-start;
            padding: 0 20px;
        }

        a {
            color: $black;
            font-weight: 500;

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            &:hover {
                color: $cc-green;
            }

            &.Lock {
                pointer-events: none;
            }


            &:first-child {

                &:hover {
                    svg {
                        transform: translateX(-5px);
                    }
                }

                svg {
                    margin-right: 10px;
                    transition: transform .3s ease;
                }
            }

            &:last-child {

                &:hover {
                    svg {
                        transform: translateX(5px);
                    }
                }

                svg {
                    margin-left: 10px;
                    transition: transform .3s ease;
                }
            }
        }
    }

    &-lock {
        color: $cc-grey;
        margin-bottom: 0;
        font-weight: 500;
        cursor: not-allowed;

        svg {
            margin-right: 10px;
            path {
                fill: $cc-grey;
            }
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}