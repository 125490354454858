.RealisationsBlock {
    margin-top: 200px;
    margin-bottom: 200px;
    display: grid;
    grid-template-columns: repeat(2, 566px);
    grid-gap: 0 150px;
    justify-content: center;

    @include mq($from: 1151px, $until: 1280px) {
        grid-template-columns: repeat(2,450px);
        grid-gap: 0 50px;
    }

    @include mq($from: 556px, $until: 1150px) {
        grid-template-columns: 566px;
        grid-gap: 0 30px;
        margin-top: 50px;
        margin-bottom: 50px;
        padding: 0 20px;
    }

    @include mq($until: 555px) {
        grid-template-columns: 1fr;
        grid-gap: 0 30px;
        margin-top: 50px;
        margin-bottom: 50px;
        padding: 0 20px;
    }

    &-content {
        height: fit-content;

        @include mq($until: 768px) {
            margin-bottom: 20px;
        }
    }

    &-subtitle {
        color: $cc-green;
        font-size: 14px;
        font-weight: 500;
        font-family: $font-Gotham;
        margin-bottom: 30px;
        text-transform: uppercase;
        letter-spacing: 1px;

        @include mq($until: 768px) {
            margin-bottom: 20px;
        }
    }

    &-title {
        font-size: 45px;
        font-family: $font-Gotham;
        font-weight: 200;

        p {
            margin-bottom: 0;
        }

        @include mq($until: 768px) {
            font-size: 35px;
        }
    }

    .Realisations-tile {
        margin-top: 0;

        @include mq($from: 768px, $until: 1150px) {
            margin-top: 50px;
        }

        @include mq($until: 768px) {
            margin-top: 30px;
        }
        
        &:nth-child(2) {
            margin-bottom: 140px;

            @include mq($until: 1150px) {
                margin-bottom: 0;
            }
        }

        &:nth-child(3) {
            margin-top: -260px !important;

            @include mq($from: 768px, $until: 1150px) {
                margin-top: 50px !important;
            }

            @include mq($until: 768px) {
                margin-top: 30px !important;
            }
        }
    }
}