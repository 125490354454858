/* General */
// Tabbing
body:not(.is-tabbing) {

    span:focus,
    button:focus,
    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
    }
}

.Main {
    position: relative;
    margin-top: 124px;
    overflow: hidden;

    @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
        margin-top: 90px;
    }
}

.Error {
    color: $black;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.mediaContainer {
    @include mq($from: 1921px, $media-type: $mq-type, $mq_debug: null) {
        margin-left: auto;
        margin-right: auto;
        width: 1920px;
    }
}