.HomeBanner {
    position: relative;
    width: 100vw;
    height: calc(100vh - 124px);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    background-color: rgba($color: #333333, $alpha: 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include mq($until: 1024px) {
        height: calc(100vh - 90px);
    }

    &-title {
        text-align: center;
        font-size: 70px;
        font-family: $font-Gotham;
        font-weight: 100;
        color: $white;

        p {
            margin-bottom: 0;
        }

        @include mq($until: 600px) {
            font-size: 35px;
        }
    }

    &-cta {
        position: absolute;
        bottom: 80px;
        right: 100px;
        z-index: 10;

        .Button {
            color: $white;
        }

        @include mq($until: 600px) {
           right: unset;
        }
    }

    &-element {
        position: absolute;
        bottom: -88px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        z-index: 1;
    }
}