.Thanks {
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
    width: 960px;
    text-align: center;

    @include mq($until: 1024px, $media-type: $mq-type, $mq_debug: null) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 50px;
    }

    &-title {
        margin-bottom: 30px;
    }

    &-text {
        font-family: $font-Gotham;
        font-size: 20px;
        color: $cc-grey;
        margin-bottom: 50px;

        a {
            color: $black;

            &:hover, &:active, &:focus {
                color: $cc-grey;
            }
        }

        strong {
            color: $black;
            font-weight: 400;
        }

        h1 {
            margin-bottom: 20px;
            font-size: 38px;
        }

        h2 {
            margin-bottom: 15px;
            font-size: 28px;
        }

        h3 {
            margin-bottom: 10px;
        }
    }
}