.TextMedia {
    display: grid;
    grid-template-columns: 480px 540px;
    grid-gap: 260px;
    margin-left: 320px;

    @include mq($from: 1920px) {
        justify-content: center;
        margin-left: 0;
    }

    @include mq($from: 1100px, $until: 1600px) {
        margin-left: 50px;
        grid-gap: 150px;
    }

    @include mq($from: 769px, $until: 1100px) {
        grid-template-columns: 768px;
        justify-content: center;
        grid-gap: 50px;
        margin-left: 0;
        padding: 0 20px;
    }

    @include mq($until: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 50px;
        margin-left: 0;
        padding: 0 20px;
    }

    &-content {

        &--subtitle {
            color: $cc-green;
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: 500;
            text-transform: uppercase;
            font-family: $font-Gotham;
            margin-bottom: 30px;

            @include mq($until: 768px) {
                margin-bottom: 20px;
            }
        }

        &--title {
            font-size: 45px;
            font-weight: 200;
            font-family: $font-Gotham;
            color: $black;
            margin-bottom: 50px;

            @include mq($until: 768px) {
                margin-bottom: 30px;
                font-size: 35px;
            }
        }

        &--text {
            color: $cc-grey;
            margin-bottom: 50px;
            line-height: 26px;

            @include mq($until: 768px) {
                margin-bottom: 30px;
            }
        }
    }

    &-image {
        width: 860px;
        height: 540px;

        @include mq($from: 769px, $until: 1280px) {
            width: 100%;
        }

        @include mq($until: 768px) {
           width: 100%;
           height: 320px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-biotop {
        margin-left: -20px;
        display: grid;
        grid-template-columns: 116px 275px;
        align-items: center;

        @include mq($until: 400px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 1fr;
            justify-content: center;
        }

        &--content {
            font-size: 14px;
            font-weight: 500;
            color: $cc-green;

            @include mq($until: 400px, $media-type: $mq-type, $mq_debug: null) {
                margin-left: 20px;
            }

            p {
                margin-bottom: 0;
            }
        }

        img {
            width: 116px;
            height: 116px;
        }
    }
}

.MediaText {
    display: grid;
    grid-template-columns: 640px 480px;
    grid-gap: 160px;
    margin-left: 160px;
    margin-top: 150px;
    margin-bottom: 100px;

    @include mq($from: 1920px) {
        justify-content: center;
        margin-left: 0;
    }

    @include mq($from: 1281px, $until: 1600px) {
        margin-left: 50px;
        grid-gap: 100px;
    }

    @include mq($from: 769px, $until: 1280px) {
        grid-template-columns: 768px;
        justify-content: center;
        grid-gap: 50px;
        margin-left: 0;
        padding: 0 20px;
    }

    @include mq($until: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 50px;
        margin-left: 0;
        margin-top: 50px;
        margin-bottom: 50px;
        padding: 0 20px;
    }

    &-image {
        width: 640px;
        height: 400px;

        @include mq($from: 769px, $until: 1280px) {
            width: 100%;
        }

        @include mq($until: 768px) {
           width: 100%;
           height: 320px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}