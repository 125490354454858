.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .4s ease;    

    &.invisible {
        pointer-events: none;
        opacity: 0;
    }

    .form-container {
        background-color: white;        
        margin: 0 auto;
        max-width: 680px;
        width: 100%;
        padding: 60px 40px;
        position: relative;

        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 32px;
            height: 32px;
            cursor: pointer;

            &:before, &:after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 33px;
                width: 2px;
                background-color: #333;
                top: 0;                
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }              
        }

        form {
            label {
                font-weight: 500;
                font-size: 14px;
                text-transform: uppercase;
                color: #000000;
                letter-spacing: 1px;
                margin-bottom: 0;
                position: relative;
            }

            input {
                appearance: none;
                width: 100%;
                padding: 15px 0;
                border-radius: 0;
                border-top: hidden;
                border-left: hidden;
                border-right: hidden;
                border-bottom: 2px solid #B4B4B4;

                &:focus, &:active {
                    border-bottom: 2px solid #000000;
                }
            }
        }
    }
}