.Header {
  position: relative;
  background-color: $white;
  width: 100%;
  position: fixed;
  padding: 0 160px 0px;
  top: 0;

  @include mq($from: 1100px,$until: 1600px, $media-type: $mq-type, $mq_debug: null) {
    padding: 0 50px 0px;
  }

  @include mq($until: 1099px, $media-type: $mq-type, $mq_debug: null) {
    padding: 0 20px 0px;
    height: 90px;
  }
}

.HeaderColor {
  background-color: $black;
  transition: background-color 2s ease;
}