/* Logo */
.Logo {
    position: relative;
    z-index: 10;

    &-large {
        display: block;

        @include mq($until: 1500px, $media-type: $mq-type, $mq_debug: null) {
            display: none;
        } 
    }

    &-small {
        display: none;

        @include mq($until: 1500px, $media-type: $mq-type, $mq_debug: null) {
            display: block;
        } 
    }
}

