.Parallax {
    width: 100vw;
    height: 1010px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    @include mq($until: 600px) {
        height: 320px;
        background-attachment: unset;
    }
}