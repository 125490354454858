.Method {
    position: relative;
    background-color: $cc-lightGrey;
    padding: 230px 0 160px 150px;
    margin-left: 320px;
    margin-bottom: 350px;
    margin-top: 300px;

    @include mq($from: 1921px) {
        max-width: 1600px;
        margin: 300px auto 350px
    }

    @include mq($from: 1281px,$until: 1440px) {
        margin-left: 160px;
    }

    @include mq($from: 769px,$until: 1280px) {
        margin-left: 0;
        margin-bottom: 150px;
        padding: 150px 50px 100px 50px;
    }

    @include mq($until: 768px) {
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 0;
        padding: 50px 20px;
    }

    &-smallImage {
        position: absolute;
        top: -200px;
        right: 570px;
        width: 480px;
        height: 300px;

        @include mq($from: 1281px,$until: 1440px) {
            right: 200px;
        }

        @include mq($from: 769px,$until: 1280px) {
            right: 0;
        }

        @include mq($until: 768px) {
            position: relative;
            top: unset;
            right: unset;
            width: 100%;
            margin-bottom: 50px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-title {
        margin-bottom: 120px;
        font-size: 45px;
        font-weight: 200;
        font-family: $font-Gotham;

        @include mq($from: 769px,$until: 1280px) {
            margin-bottom: 50px;
        }

        @include mq($until: 768px) {
            margin-bottom: 30px;
            font-size: 35px;
        }

        p {
            margin-bottom: 0;
        }
    }

    &-content {
        width: 480px;
        margin-left: 400px;

        @include mq($from: 769px,$until: 1280px) {
            margin-left: 0;
        }

        @include mq($until: 768px) {
            margin-left: 0;
            width: 100%;
        }

        &--text {
            color: $cc-grey;
            margin-bottom: 50px;

            @include mq($until: 768px) {
                margin-bottom: 30px;
            }
        }
    }

    &-largeImage {
        position: absolute;
        bottom: -150px;
        left: -400px;
        width: 800px;
        height: 600px;

        @include mq($from: 769px,$until: 1280px) {
            position: relative;
            left: unset;
            bottom: unset;
            width: 100%;
            margin-top: 50px;
        }

        @include mq($until: 768px) {
            position: relative;
            bottom: unset;
            left: unset;
            width: 100%;
            height: 320px;
            margin-top: 50px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}