.Blog {

    &-tiles {
        max-width: 1100px;
        margin: 0 auto 200px;

        a {
            .Blog-tile {
                margin-bottom: 65px;
            }

            &:last-child {
                .Blog-tile {
                    margin-bottom: 0px;
                }
            }
        }
    }

    &-tile {
        display: grid;
        grid-template-columns: 460px 640px;
        align-items: center;
        transition: box-shadow .3s ease;

        @include mq($from: 768px, $until: 1100px) {
            grid-template-columns: 450px 1fr;
        }

        @include mq($until: 768px) {
            grid-template-columns: 1fr;
        }

        &:hover {
            cursor: pointer;
            box-shadow: 0px 3px 36px #E2E2E2;

            .Blog-tile--content {
                background-color: $white;
            }

            .Blog-tile--content {
                svg {
                    opacity: 1;
                    -webkit-animation-name: arrowRightLeft;
                    -moz-animation-name: arrowRightLeft;
                    -o-animation-name: arrowRightLeft;
                    animation-name: arrowRightLeft;
                    animation-duration: 1.2s;
                    animation-iteration-count: infinite;
                }
            }
        }

        &--image {
            width: 460px;
            height: 100%;

            @include mq($until: 768px) {
                width: 100%;
                height: 320px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--content {
            transition: background-color .3s ease;
            padding: 20px 90px 20px 80px;

            @include mq($until: 768px) {
                padding: 20px 20px 20px 20px;
            }
        }

        &--date {
            font-size: 14px;
            font-weight: 500;
            font-family: $font-Gotham;
            color: $cc-green;
        }
        
        &--title {
            font-size: 26px;
            font-weight: 300;
            font-family: $font-Gotham;
            color: $black;
            margin-top: 15px;
            margin-bottom: 30px;
        }

        &--text {
            color: $cc-grey;
            margin-bottom: 50px;

            @include mq($until: 600px) {
                margin-bottom: 30px;
            }
        }

        &--cta {
            font-weight: 500;

            svg {
                margin-left: 10px;
                opacity: 0;
                transition: opacity .3s ease;
            }
        }
    }
}

.BlogDetail {

    &-banner {
        background-color: #F4F4F4;
        padding-bottom: 400px;

        @include mq($until: 768px) {
            padding-bottom: 250px;
        }
    }

    &-date {
        font-size: 14px;
        font-weight: 500;
        font-family: $font-Gotham;
        color: $cc-green;
    }

    &-content {
        max-width: 960px;
        margin: 0 auto;

        @include mq($until: 960px) {
            padding: 0 20px;
        }

        &--textblock {

            h2 {
                font-size: 26px;
                font-weight: 300;
                color: $black;
                font-family: $font-Gotham;
                margin-bottom: 50px;
                
                @include mq($until: 768px) {
                    margin-bottom: 30px;
                }
            }

            h3, h4 {
                color: $cc-green;
                font-size: 14px;
                font-weight: 500;
            }

            p {
                color: $cc-grey;
                font-weight: 400;
                font-size: 16px;
            }
        }

        &--video {
            width: 100%;
            height: 500px;
            margin-top: 100px;
            margin-bottom: 100px;

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include mq($until: 768px) {
                margin-top: 50px;
                margin-bottom: 50px;
                height: 320px;
            }
        }

        &--quote {
            color: $black;
            font-size: 45px;
            font-weight: 200;
            line-height: 55px;
            width: 800px;
            margin: 100px auto;
            font-family: $font-Gotham;

            @include mq($until: 768px) {
                margin: 50px auto;
                width: 100%;
                font-size: 35px;
                padding: 0 20px;
            }
        }

        &--image {
            
            &--one {
                margin-top: 100px;
                width: 100%;
                height: 560px;
                margin-bottom: 100px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                    height: 320px;
                    margin-top: 50px;
                    margin-bottom: 50px;
                }
            }

            &--two {
                display: grid;
                grid-template-columns: repeat(2, 460px);
                grid-template-rows: 560px;
                justify-content: center;
                grid-gap: 40px;
                margin-top: 100px;
                margin-bottom: 100px;

                @include mq($from: 769px, $until: 1200px, $media-type: $mq-type, $mq_debug: null) {
                    grid-template-columns: repeat(2, 375px);
                    grid-gap: 20px;
                }

                @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                    margin-top: 50px;
                    margin-bottom: 50px;
                    grid-template-rows: 320px 320px;
                    grid-template-columns: 1fr;
                    grid-gap: 30px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &-text {
        margin-top: 25px;
        color: $cc-grey;
    }

    &-image {
        width: 1280px;
        height: 600px;
        margin: -324px auto 100px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include mq($until: 768px) {
            width: 100%;
            height: 320px;
            margin: -225px auto 50px;
        }
    }

    &-share {
        display: flex;
        justify-content: space-between;
        margin-bottom: 150px;

        @include mq($until: 768px) {
            flex-direction: column;
            justify-content: flex-start;
            margin-bottom: 50px;
        }
    }
}

@keyframes arrowRightLeft {
    0%, 100% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(5px);
    }
}