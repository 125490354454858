.Banner {
    height: 620px;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba($color: #141414, $alpha: .44);
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 200px;

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        margin-bottom: 50px;
        height: 400px;
        padding: 0 20px;
    }

    &-title {
        text-transform: uppercase;
        font-size: 35px;
        font-family: $font-Gotham;
        color: $white;
        hyphens: auto;

        p {
            margin-bottom: 0;
            font-weight: 300;
        }

        strong {
            font-weight: 500;
        }
    }
}