/* Nav */
.Nav {

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
        list-style-type: none;

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex: 0 1 auto;

        @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
        } 
    }

    &-item {
        margin: 0;
        list-style: none;

        &.has-dropdown {
            position: relative;

            svg {
                margin-left: 3px;
            }

            .Nav-link {

                svg {
                    transition: transform .3s ease ;
                }

            }

            @include mq($from: 1051px) {

                &:hover {
                    .Nav-link {
                        svg {
                            transform: rotate(180deg);
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .Nav-dropdown {
                        opacity: 1;
                        visibility: visible;
                        width: fit-content;

                        &::before {
                            height: 30px;
                        }
                    }
                }
            }

            @include mq($until: 1050px) {

                .Nav-link {
                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    &-link {
        position: relative;
        user-select: none;
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: $black;
        text-decoration: none;
        margin-left: 30px;
        padding-bottom: 15px;
        // text-transform: uppercase;

        @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 20px;
            margin-left: 0;
            text-align: center;
            margin-bottom: 10px;
            color: $white;
        }

        
        &::after {
            content: "";
            position: absolute;
            height: 1px;
            left: 0;
            width: 0;
            bottom: 0;
            z-index: 1;
            margin: 0 auto;
            background-color: $cc-green;
            transition-property: width;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
        }

        @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 20px;
            margin-bottom: 10px;
            color: $white;
            padding-bottom: 0;

            &::after {
                display: none;
            }
        }

        &:hover, &:focus, &:active {
            color: $cc-black;

            @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
                color: $cc-green;

                &::after {
                    display: none;
                }
            } 

            &::after {
                content: "";
                width: 100%;
            }
        }
        
        &.is-active {
            color: $cc-black;

            @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
                color: $cc-green;
            } 
            
            &::after {
                content: "";
                width: 100%;
            }
        }

        &.disable-active {
            color: $cc-black;

            @include mq($until: 1050px, $media-type: $mq-type, $mq_debug: null) {
                color: $white;
            } 

            &::after {
                content: "";
                width: 0;
            }
        }
    }

    &-dropdown {

        @include mq($until: 1050px) {
            position: relative;
            opacity: 1;
            padding-left: 0;
            padding-bottom: 0;
            padding-top: 0;
            padding-right: 0;
            top: auto;
            justify-content: center;
            visibility: visible;
            background: transparent;
            margin: 0 auto;
            width: fit-content;
            height: auto;
            text-align: center;
            transition: opacity .3s ease, visibility .3s ease, height .3s ease;
            border-top: none;

            &::before {
                content: '';
                background-color: $white;
                width: 1px;
                height: 0;
                position: absolute;
                top: 0;
                left: -10px;
                transition: height .3s ease;
            }

            .Nav-item {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        position: absolute;
        opacity: 0;
        list-style: none;
        visibility: hidden;
        transition: opacity ease .2s, visibility ease .4s, width .2s ease;
        top: 32px;
        text-align: left;
        right: 0;
        left: 0px;
        display: flex;
        white-space: nowrap;
        padding: 25px 20px 20px 20px;
        width: 0;
        background-color: $white;
        border-top: $cc-green 1px solid;
        
        &-container {

            .Nav-item {
                margin-bottom: 5px;

                a {
                    display:inline-block;
                    color: $black;
                    font-size: 14px;
                    margin-bottom: 10px;
                    font-weight: 400;
                    transition: color .3s ease;
                    line-height: 1;

                    &:hover {
                        color: $cc-green;
                    }

                    &.is-active {
                        color: $cc-green;
                    }

                    @include mq($until: 1050px) {
                        color: $white !important;

                        &:hover {
                            color: $black;
                        }

                        &.is-active {
                            color: $cc-green !important;
                        }
                    }
                }
            }
        }
    }
}
