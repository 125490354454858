/* Cookie */
.CookieConsent {
  background-color: $black;
  color: $white;
  padding: u(15) 0;
  transition-property: transform, opacity, visibility;
  transition-duration: .2s;
  transition-delay: 0s, .2s, .2s;

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  opacity: 1;

  p {
      margin-bottom: 0;
  }

  .Button--primary:not(.Button--outline):hover {
    color: $white !important;
  }
}


.CookieConsent:not(.is-active) {
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
}

.CookieConsent > .Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cookie-text {
  font-size: u(15);
}

.CookieConsent  a {
  font-weight: bold;
  margin-left: u(10);
  color: $cc-lightGrey;
  text-decoration: underline;

  &,
  &:active,
  &:focus,
  &:hover {
      color: $white;
  }
}

.Close {
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  font-size: 150%;
  margin-left: u(10);
  line-height: 1;
  transition: opacity .2s;

  &:not(:hover) {
      opacity: .75;
  }
}

