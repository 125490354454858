.Footer {
    max-width: 1600px;
    margin: 0 auto;

    @include mq($from: 961px, $until: 1599px) {
        width: 100%;
        padding: 0 50px;
    }

    @include mq($from: 591px, $until: 960px) {
        width: 100%;
        padding: 0 20px;
    }

    @include mq($until: 590px) {
        width: 100%;
        padding: 0 20px;
    }

    &-top {
        display: grid;
        grid-template-columns: 334px 464px 560px 239px;
        padding: 45px 0 50px;

        @include mq($from: 1101px, $until: 1599px) {
            grid-template-columns: 334px 1fr 1fr 239px;
        }

        @include mq($from: 961px, $until: 1100px) {
            grid-template-columns: 334px 1fr 1fr;
        }

        @include mq($from: 591px, $until: 960px) {
            grid-template-columns: 1fr 1fr;
        }

        @include mq($until: 590px) {
            grid-template-columns: 1fr;
        }
    }

    &-info {
        margin-top: 55px;

        &--data {
            margin-top: 40px;
            display: flex;
            flex-direction: column; 
        }
    }

    &-data {
        font-size: 16px;
        font-weight: 400;
        font-family: $font-Gotham;
        color: $cc-grey;
        margin-bottom: 7px;
    }

    &-nav {
        display: flex;
        flex-direction: column;
        margin-top: 55px;
    }

    &-socials {
        display: flex;
        justify-content: space-between;
        
        &-container {
            margin-top: 55px;
            display: flex;
            flex-direction: column;
        }

        figure {
            margin-top: 34px;
            margin-right: -21px;
        }
    }

    &-link {
        font-size: 16px;
        font-weight: 400;
        font-family: $font-Gotham;
        color: $cc-grey;
        margin-bottom: 7px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-bottom {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        border-top: 1px solid $cc-border;

        @include mq($until: 310px) {
            flex-direction: column;
        }

        a {
            font-size: 14px;
            font-weight: 400;
            font-family: $font-Gotham;
            color: $cc-grey;

            &:hover {
                color: $black;
            }
        }
    }
}